import * as React from 'react';
import { cn } from '@shared/utils/styles';
import { Input } from '@shared/react_components/Input';
import Icon from '@shared/react_components/Icon';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export const SearchBar = ({ className, ...props }: Props) => {
  return (
    <div className="tw-w-full tw-relative tw-max-w-[500px]">
      <Input
        id="ecpSearch"
        placeholder="Search"
        className={cn('tw-pl-9 tw-h-9', className)}
        startAdornment={<Icon name="MagnifyingGlass" size={16} className="tw-text-muted-foreground" />}
        {...props}
      />
    </div>
  );
};
