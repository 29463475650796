import { cn } from '@shared/utils/styles';
import * as React from 'react';
import { mergeClasses } from './css_utils';

export const INPUT_SIZE_CLASSNAMES = 'tw-flex tw-h-9 tw-w-full tw-px-3 tw-py-2 tw-text-sm';
export const INPUT_BORDER_CLASSNAMES = 'tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200';
export const INPUT_COLOR_CLASSNAMES = 'tw-bg-background tw-text-foreground placeholder:tw-text-muted-foreground';
export const INPUT_FOCUS_RING_CLASSNAMES =
  'focus-visible:tw-outline-none focus-visible:tw-ring-[2px] focus-visible:tw-ring-primary-100 dark:focus-visible:tw-ring-primary-200';
export const INPUT_DISABLED_CLASSNAMES = 'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-neutral-100';

export const INPUT_CLASSNAMES = [
  INPUT_SIZE_CLASSNAMES,
  INPUT_BORDER_CLASSNAMES,
  INPUT_COLOR_CLASSNAMES,
  INPUT_FOCUS_RING_CLASSNAMES,
  INPUT_DISABLED_CLASSNAMES,
];
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startAdornment?: React.ReactNode;
  isInvalid?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, startAdornment, isInvalid, ...props }, ref) => {
  return (
    <div className="tw-relative tw-w-full">
      {startAdornment && (
        <div
          className={mergeClasses(
            'tw-absolute tw-left-[2px] tw-top-[1px] tw-bottom-[1px] tw-rounded-tl-lg tw-rounded-bl-lg tw-w-8 tw-flex tw-items-center tw-justify-center tw-pointer-events-none'
          )}
        >
          <span
            className={mergeClasses('tw-text-base tw-font-normal tw-m-0 tw-leading-none tw-ml-[2px]', {
              'tw-text-neutral-600': !isInvalid,
              'tw-text-red-500': isInvalid,
            })}
          >
            {startAdornment}
          </span>
        </div>
      )}
      <input
        type={type}
        className={mergeClasses(
          ...INPUT_CLASSNAMES,
          {
            'tw-pl-8': startAdornment,
            'tw-border-error-300 focus-visible:tw-border-error-300 focus-visible:tw-ring-error-300': isInvalid,
          },
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
