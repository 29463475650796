import React, { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarDisplayMode } from '../types';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '@shared/utils/styles';

interface Props {
  id: string;
  href?: string;
  isCurrent?: boolean;
  isLast?: boolean;
  onClick?: () => void;
  icon: ReactNode;
  displayMode: SidebarDisplayMode;
  onMouseOver?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}

export default function SidebarLink({
  id,
  href,
  isCurrent,
  isLast,
  onClick,
  icon,
  displayMode,
  onMouseOver,
  onMouseLeave,
  children,
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();

  return (
    <button
      id={id}
      onClick={() => {
        if (href) {
          navigate(href);
        }

        if (onClick) {
          onClick();
        }
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={cn('tw-group tw-appearance-none tw-border-none tw-shadow-none tw-w-full tw-px-0 tw-pt-0 tw-pb-2', {
        '!tw-pb-0': isLast,
      })}
    >
      <div
        className={cn(
          'tw-w-full tw-rounded-lg tw-h-10 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2 tw-transition group-hover:tw-text-primary-500 tw-relative tw-will-change-auto tw-z-20',
          { 'tw-text-neutral-900': !isCurrent, 'tw-text-primary-500': isCurrent }
        )}
        style={{
          paddingLeft: displayMode === 'expanded' ? '0.5rem' : '0.6875rem',
          transition: '0.2s ease-in-out',
        }}
      >
        <div className="group-active:tw-scale-95 tw-transition-transform tw-duration-200 tw-will-change-transform">{icon}</div>

        <AnimatePresence>
          {displayMode === 'expanded' && (
            <motion.div
              variants={{
                collapsed: { opacity: 0 },
                expanded: { opacity: 1 },
              }}
              initial="collapsed"
              animate="expanded"
              exit="collapsed"
              transition={{ duration: 0.2 }}
              className="tw-absolute tw-left-10 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2"
            >
              <span className="tw-text-base tw-whitespace-nowrap">{children}</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </button>
  );
}
