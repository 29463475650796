import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { EVENTS } from '@shared/constants/events';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import posthog from 'posthog-js';
import invariant from 'tiny-invariant';
import { SidebarDisplayMode } from '../types';
import UserDropdown from './UserDropdown';
import ContextSwitcher from './ContextSwitcher';
import Logo from './Logo';
import MenuButton from './MenuButton';
import ECPResourceSwitcher from './ResourceSwitcher/ECPResourceSwitcher';
import LabResourceSwitcher from './ResourceSwitcher/LabResourceSwitcher';
import Icon from '@shared/react_components/Icon';
import { Button } from '@shared/react_components/Button';
import { useFeatureFlagEnabled } from '@shared/hooks/useFeatureFlagEnabled';

interface Props {
  sidebarDisplayMode: SidebarDisplayMode;
  setSidebarDisplayMode: Dispatch<SetStateAction<SidebarDisplayMode>>;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Navbar({ sidebarDisplayMode, setSidebarDisplayMode, setSidebarOpen }: Props) {
  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null in lab dropdown');

  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const isDarkModeToggleEnabled = useFeatureFlagEnabled('dark-mode-toggle-enabled');

  const isLabUser = user.user_type === 'labUser';
  const isEcpUser = user.user_type === 'ecpUser';

  const canSwitchContext = (isLabUser && user.office_metadata.length > 0) || (isEcpUser && user.labs.length > 0);

  // Load theme from local storage or default to 'light'
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    if (savedTheme) {
      setTheme(savedTheme);
      document.documentElement.classList.toggle('dark', savedTheme === 'dark');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark', newTheme === 'dark');
    localStorage.setItem('theme', newTheme);
  };

  return (
    <nav
      id="sc-navbar"
      className="app-header tw-w-full tw-h-[3.5rem] tw-px-2 md:tw-px-5 tw-flex tw-justify-between tw-items-center tw-flex-row tw-relative tw-z-30 tw-bg-primary-500 print:tw-hidden"
    >
      <div className="tw-h-full tw-flex tw-justify-center tw-items-center flex-row tw-gap-1 md:tw-gap-2">
        <div className="tw-hidden md:tw-flex">
          <MenuButton
            sidebarDisplayMode={sidebarDisplayMode}
            onMenuButtonClick={() => {
              // Emit posthog event + set new state
              const newDisplayMode = sidebarDisplayMode === 'collapsed' ? 'expanded' : 'collapsed';
              posthog.capture(newDisplayMode === 'expanded' ? EVENTS.SIDEBAR_EXPANDED : EVENTS.SIDEBAR_COLLAPSED);
              setSidebarDisplayMode(newDisplayMode);
            }}
          />
        </div>
        <div className="md:tw-hidden">
          <MenuButton sidebarDisplayMode={sidebarDisplayMode} onMenuButtonClick={() => setSidebarOpen((isOpen) => !isOpen)} />
        </div>
        <Logo showText={!canSwitchContext} />
        {canSwitchContext && <ContextSwitcher />}
        {isLabUser ? <LabResourceSwitcher /> : <ECPResourceSwitcher />}
      </div>
      <div className="tw-h-full tw-flex tw-justify-center tw-items-center flex-row">
        {isDarkModeToggleEnabled && (
          <Button size="icon" onClick={toggleTheme}>
            <Icon name={theme === 'light' ? 'Moon' : 'Sun'} size={24} className="tw-text-white" />
          </Button>
        )}
        <UserDropdown />
      </div>
    </nav>
  );
}
