import { Button } from '@shared/react_components/Button';
import Icon from '@shared/react_components/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/react_components/Tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  displayMode: 'expanded' | 'collapsed';
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
};
function CreateOrderButton({ displayMode, setSidebarOpen }: Props) {
  const navigate = useNavigate();
  return (
    <Tooltip disableHoverableContent delayDuration={0}>
      <TooltipTrigger asChild>
        <Button
          onClick={() => {
            navigate('./create-order');
            setSidebarOpen(false);
          }}
          className="tw-w-full tw-h-12 tw-gap-2 tw-shadow-none tw-relative tw-flex tw-items-center tw-justify-start tw-flex-row tw-will-change-auto"
          style={{
            paddingLeft: displayMode === 'expanded' ? '0.9375rem' : '0.75rem',
            transition: '0.2s ease-in-out',
          }}
        >
          <div className="tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
            <Icon name="CreateOrder" size={24} />
          </div>
          <AnimatePresence>
            {displayMode === 'expanded' && (
              <motion.div
                variants={{
                  collapsed: { opacity: 0 },
                  expanded: { opacity: 1 },
                }}
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                transition={{ duration: 0.2 }}
                className="tw-absolute tw-left-12 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2"
              >
                <span className="tw-text-base">Create Order</span>
              </motion.div>
            )}
          </AnimatePresence>
        </Button>
      </TooltipTrigger>
      {displayMode === 'collapsed' && (
        <TooltipContent side="right" sideOffset={25} withArrow={false}>
          Create Order
        </TooltipContent>
      )}
    </Tooltip>
  );
}

export default CreateOrderButton;
