import { AnimatePresence, motion } from 'framer-motion';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { SidebarDisplayMode } from '../types';
import SidebarLink from './SidebarLink';
import { Link } from './types';

const TOOLTIP_DEFAULT_Y = 76;
const TOOLTIP_DEFAULT_WIDTH = 54;
const TOOLTIP_OFFSET_Y = 4;
const TOOLTIP_PADDING_X = 16;
const TOOLTIP_LABEL_Y_TRAVEL_DISTANCE = 40;
const TOOLTIP_LABEL_DURATION = 0.2;

interface TooltipContext {
  top?: number;
  width?: number;
  opacity: number;
}

interface Props {
  groupId: string;
  links: Link[];
  displayMode: SidebarDisplayMode;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export default function SidebarLinkGroup({ groupId, links, displayMode, setSidebarOpen }: Props) {
  const location = useLocation();
  const pathname = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;

  const getLinkElementByIndex = (index: number) => document.getElementById(`${groupId}-link-${index}`);

  const getTooltipLabelWidthByIndex = (index: number) => {
    const label = document.getElementById(`${groupId}-tooltip-${index}-measure`);
    return label?.offsetWidth ?? TOOLTIP_DEFAULT_WIDTH - TOOLTIP_PADDING_X;
  };

  const [tooltipIndex, setTooltipIndex] = useState(0);
  const [direction, setDirection] = useState<'up' | 'down'>('down');
  const [linkBackgroundContext, setLinkBackgroundContext] = useState({ top: 0, opacity: 0 });
  const [tooltipContext, setTooltipContext] = useState<TooltipContext>({
    top: undefined,
    width: undefined,
    opacity: 0,
  });

  const currentLink = useMemo(() => links.find((link) => link?.matchPattern?.test(pathname)), [links, pathname]);

  const highlightCurrentLink = useCallback(() => {
    if (currentLink) {
      const elem = getLinkElementByIndex(links.indexOf(currentLink));

      if (elem) {
        setLinkBackgroundContext({
          top: elem.offsetTop,
          opacity: 1,
        });
      }
    }
  }, [currentLink, links, groupId]);

  useEffect(() => {
    if (!tooltipContext.top) {
      setTooltipContext((prev) => {
        const firstLink = getLinkElementByIndex(0);
        const firstLinkRect = firstLink?.getBoundingClientRect();
        const top = (firstLinkRect?.top ?? TOOLTIP_DEFAULT_Y) + TOOLTIP_OFFSET_Y ?? TOOLTIP_DEFAULT_Y;

        return {
          ...prev,
          top,
        };
      });
    }

    if (!tooltipContext.width) {
      setTooltipContext((prev) => {
        return {
          ...prev,
          width: getTooltipLabelWidthByIndex(0),
        };
      });
    }
  }, [tooltipContext]);

  useEffect(() => {
    // Remove the background color from the current link
    setLinkBackgroundContext({ ...linkBackgroundContext, opacity: 0 });
    // Highlight the new link
    highlightCurrentLink();
  }, [pathname]);

  return (
    <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-relative">
      {links.map((link, index) => (
        <SidebarLink
          key={link.label}
          id={`${groupId}-link-${index}`}
          href={link.href}
          onClick={() => {
            link.onClick?.();
            setSidebarOpen(false);
          }}
          isCurrent={link?.matchPattern?.test(pathname)}
          isLast={index === links.length - 1}
          icon={link.icon}
          displayMode={displayMode}
          onMouseOver={(ev) => {
            const offsetTop = (ev.currentTarget as HTMLDivElement).offsetTop;
            const rectTop = (ev.currentTarget as HTMLDivElement).getBoundingClientRect().top;

            setLinkBackgroundContext({
              top: offsetTop,
              opacity: 1,
            });

            setTooltipContext({
              top: rectTop + TOOLTIP_OFFSET_Y,
              opacity: 1,
              width: getTooltipLabelWidthByIndex(index),
            });

            setTooltipIndex(index);
            setDirection(tooltipIndex < index ? 'down' : 'up');
          }}
          onMouseLeave={(ev) => {
            const offsetTop = (ev.currentTarget as HTMLDivElement).offsetTop;
            const rectTop = (ev.currentTarget as HTMLDivElement).getBoundingClientRect().top;

            setLinkBackgroundContext({
              top: offsetTop,
              opacity: 0,
            });

            setTooltipContext({
              top: rectTop + TOOLTIP_OFFSET_Y,
              opacity: 0,
              width: getTooltipLabelWidthByIndex(index),
            });

            setDirection(tooltipIndex < index ? 'down' : 'up');
            highlightCurrentLink();
          }}
        >
          {link.label}
        </SidebarLink>
      ))}

      <div
        className="tw-absolute tw-left-0 tw-w-full tw-rounded-lg tw-h-10 tw-bg-primary-50 tw-pointer-events-none tw-z-10"
        style={{
          transition: '0.2s ease-in-out',
          top: linkBackgroundContext.top,
          opacity: linkBackgroundContext.opacity,
        }}
      ></div>

      {displayMode === 'collapsed' &&
        createPortal(
          <div
            className="tw-fixed tw-z-[1001] tw-h-8 tw-left-[5.5rem] tw-rounded-lg tw-bg-gray-800 tw-text-white tw-px-3 tw-py-1 tw-flex tw-items-center tw-justify-center tw-flex-col tw-pointer-events-none tw-overflow-hidden"
            style={{
              transition: '0.2s ease-in-out',
              width: (tooltipContext?.width ?? TOOLTIP_DEFAULT_WIDTH) + TOOLTIP_PADDING_X,
              top: tooltipContext.top,
              opacity: tooltipContext.opacity,
            }}
          >
            {links.map((link, index) => (
              <AnimatePresence key={link.label} custom={index}>
                {tooltipIndex === index && (
                  <motion.div
                    custom={index}
                    variants={{
                      hidden: {
                        y: direction === 'down' ? TOOLTIP_LABEL_Y_TRAVEL_DISTANCE : -TOOLTIP_LABEL_Y_TRAVEL_DISTANCE,
                      },
                      visible: { y: 0 },
                      exit: { y: direction === 'down' ? TOOLTIP_LABEL_Y_TRAVEL_DISTANCE : -TOOLTIP_LABEL_Y_TRAVEL_DISTANCE },
                    }}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ ease: 'easeInOut', duration: TOOLTIP_LABEL_DURATION }}
                    className="tw-absolute tw-left-2 tw-bottom-[5px]"
                  >
                    <span key={link.label} id={`${groupId}-tooltip-${index}`} className="tw-text-sm tw-whitespace-nowrap">
                      {link.label}
                    </span>
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>,
          document.body
        )}

      {links.map((link, index) => (
        <span
          key={link.label}
          id={`${groupId}-tooltip-${index}-measure`}
          className="tw-absolute tw-text-sm tw-pointer-events-none tw-whitespace-nowrap tw-invisible tw-opacity-0"
        >
          {link.label}
        </span>
      ))}
    </div>
  );
}
