import { PopoverTrigger } from '@shared/react_components/Popover';
import React from 'react';
import { ResourceIcon, ResourceMeta, ResourceName } from './components';

import ChevronDownIcon from '@assets/icons/ChevronDown-24-White.svg';
import Address from '@shared/react_components/Address';
import { Resource } from './types';

interface Props {
  currentResource: Resource;
}

export default function TriggerButton({ currentResource }: Props) {
  return (
    <PopoverTrigger asChild>
      <button
        id="sc-lab-switcher"
        className="tw-appearance-none tw-shadow-none tw-border-none tw-rounded-lg tw-px-1 md:tw-px-2 tw-py-1 tw-flex tw-justify-start tw-items-center tw-flex-row tw-gap-1 md:tw-gap-2 tw-transition hover:tw-bg-primary-600 tw-text-popover-foreground"
      >
        <ResourceIcon resourceType={currentResource.type} className="tw-bg-background tw-text-primary-500" />
        <div className="tw-hidden md:tw-flex tw-items-start tw-justify-center tw-flex-col tw-gap-[0.125rem]">
          <ResourceName className="tw-text-white tw-max-w-[12rem] tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-select-none">
            {currentResource.name}
          </ResourceName>
          <ResourceMeta className="tw-text-white tw-max-w-[12rem] tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-select-none">
            {currentResource.labInformation || <Address address={currentResource.address} />}
          </ResourceMeta>
        </div>
        <ChevronDownIcon />
      </button>
    </PopoverTrigger>
  );
}
