import useLabStore from '@shared/state/lab-store';
import useOfficeLabStore from '@shared/state/office-lab-store';
import React, { useEffect } from 'react';

const AllPermisions = [
  // Lab permissions
  'lab:create:order',
  'lab:create:payment',
  'lab:create:ecp_user',
  'lab:create:user',
  'lab:delete:note',
  'lab:delete:payment',
  'lab:delete:user',
  'lab:delete:ecp_user',
  'lab:read:business_insights',
  'lab:read:orders',
  'lab:read:payments',
  'lab:read:payment',
  'lab:read:production_report',
  'lab:read:report',
  'lab:read:sales_report',
  'lab:read:sales_report_admin',
  'lab:update:lab',
  'lab:update:payment',
  'lab:update:user',

  // ECP permissions
  'ecp:create:order',
  'ecp:create:payment',
  'ecp:create:user',
  'ecp:delete:payment',
  'ecp:delete:user',
  'ecp:read:payment',
  'ecp:read:payments',
  'ecp:update:payment',
  'ecp:update:user',
] as const;

export type PossiblePermissions = (typeof AllPermisions)[number];

type PermissionsParams = {
  requiredPermissions: PossiblePermissions[]; // The permissions required to return true
  matchAllPermissions?: boolean; // If true, the user must have all required permissions. If false, the user must have at least one of the required permissions.
  fallback?: Function; // A function to run if the user does not have the required permissions
};

export function hasRequiredPermissions({
  permissions,
  requiredPermissions,
  matchAllPermissions = true,
  fallback,
}: PermissionsParams & {
  permissions?: PossiblePermissions[];
}): boolean {
  let hasPermission = false;
  if (requiredPermissions.length > 0) {
    if (matchAllPermissions) {
      hasPermission = requiredPermissions.every((permission) => (permissions ?? []).includes(permission));
    } else {
      hasPermission = requiredPermissions.some((permission) => (permissions ?? []).includes(permission));
    }
  } else {
    hasPermission = true;
  }

  if (!hasPermission && fallback) {
    fallback();
  }

  return hasPermission;
}

/**
 * Fetches the current permissions for the user.
 * Permissions live on the lab or Ecp object, so they are fetched from the appropriate store.
 *
 * Permissions on the user are deprecated and should not be used.
 * @returns boolean
 */
export const usePermissions = ({ requiredPermissions, matchAllPermissions = true, fallback }: PermissionsParams): boolean => {
  const currentLab = useLabStore();
  const currentOffice = useOfficeLabStore();

  if (currentOffice.office?.id) {
    return hasRequiredPermissions({ permissions: currentOffice.office.permissions, requiredPermissions, matchAllPermissions, fallback });
  }

  if (currentLab.lab?.lab_id) {
    return hasRequiredPermissions({ permissions: currentLab.lab.permissions, requiredPermissions, matchAllPermissions, fallback });
  }

  return false;
};

/**
 * A component that renders its children only if the user has the required permissions.
 */
export const PermissionsFlag = ({
  requiredPermissions,
  matchAllPermissions = true,
  fallback,
  children,
}: React.PropsWithChildren & PermissionsParams) => {
  const hasPermissions = usePermissions({ requiredPermissions, matchAllPermissions, fallback });

  useEffect(() => {
    if (!hasPermissions && fallback) {
      fallback();
    }
  }, [hasPermissions, fallback]);

  if (hasPermissions) {
    return <>{children}</>;
  }

  return null;
};
