import React from 'react';
import { DropdownMenuItem } from './DropdownMenu';

import CheckPurpleIcon from '@assets/icons/Check-16-Purple.svg';

interface Props {
  href: string;
  icon?: React.ReactNode;
  label: string;
  isActive?: boolean;
}

export default function DropdownLinkItem({ href, icon, label, isActive }: Props) {
  return (
    <DropdownMenuItem asChild>
      <a href={href} className="tw-h-10 tw-min-w-[12.5rem] tw-no-underline tw-justify-between tw-gap-2">
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-row tw-gap-2">
          {icon}
          <span className="tw-text-sm tw-font-normal">{label}</span>
        </div>
        {isActive && (
          <div className="tw-relative tw-z-10 tw-flex tw-items-center tw-justify-center">
            <CheckPurpleIcon />
          </div>
        )}
      </a>
    </DropdownMenuItem>
  );
}
