import { useAuth0 } from '@auth0/auth0-react';
import { ILab } from '@core/models/lab.model';
import { IOffice } from '@core/models/office.model';
import { IPopulatedUser, UserMetadataState } from '@core/state/user-metadata/user-metadata.state';
import { IUserMetadata } from '@shared/models/user-metadata.model';
import { useMakeFetchHappen } from '@shared/useMakeFetchHappen';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const USE_POPULATE_USER_CACHE_KEY = ['populate_user'];
const USER_METADATA_KEY = 'https://speccheckrx.com/user_metadata';
const CREATED_AT_KEY = 'https://speccheckrx.com/created_at';

export type UserType = 'ecpUser' | 'labUser' | 'unknown';

function getUserType(user: UserMetadataState, currentPath: string): UserType {
  const ecps = user.ecps;
  const labs = user.labs;

  // User can be both an ecpUser and a labUser
  if (ecps.length > 0 && labs.length > 0) {
    // If the current path contains the prefix /ecp/:ecp_id/lab/:lab_id, we know we want to view the ECP View
    if (currentPath.match(/^\/ecp\/([^/]+)\/lab\/([^/]+)/)) {
      return 'ecpUser';
    }
    // Always default to labUser
    else {
      return 'labUser';
    }
  }
  // User is an ecpUser
  else if (ecps.length > 0) {
    return 'ecpUser';
  }
  // User is a labUser
  else if (labs.length > 0) {
    return 'labUser';
  }
  // Unable to classify the user
  else {
    return 'unknown';
  }
}

/**
 * Hook to get the current session user's metadata based on the Auth0 session.
 * It will fetch data from `/internal/v1/users/populate` endpoint, and cache the result indefinitely.
 *
 * For Cypress component testing: intercept `/internal/v1/users/populate?auth0_id=*` endpoint and return a mocked user object.
 */
export default function usePopulateUser() {
  const { handler: makeFetchHappen, isLoading } = useMakeFetchHappen();
  const { user } = useAuth0();
  const location = useLocation();

  const adaptPopulatedUserToUserMetadata = useCallback((populatedUser: UserMetadataState): IUserMetadata => {
    const office_metadata: IOffice[] = populatedUser.ecps.map((ecp) => {
      const adaptLabMetadata: ILab[] = ecp.lab_metadata.map((lab) => ({
        id: lab.lab_id,
        account_number: lab.account_number,
        lab_pay: lab.lab_pay_enabled,
        name: lab.lab_name,
        is_default: lab.is_default,
        intercom_settings: lab.intercom_settings,
        permissions: lab.permissions, // These are lab permissions, not applicable to ECPs
      }));

      return {
        id: ecp.ecp_id,
        name: ecp.ecp_name,
        pms: ecp.pms_name,
        address: ecp.address,
        lab_metadata: adaptLabMetadata,
        permissions: ecp.permissions, // These are ECP permissions
        sub_tier: ecp.sub_tier,
        trial_days_remaining: ecp.trial_days_remaining,
        flagged_order_threshold_days: ecp.flagged_order_threshold_days,
      };
    });
    const userType = getUserType(populatedUser, location.pathname);

    return {
      auth0ID: populatedUser.auth0_id,
      id: populatedUser.user_id,
      office_metadata,
      labs: populatedUser.labs,
      user_created_at: populatedUser.created_at,
      user_id: populatedUser.auth0_id,
      user_name: populatedUser.user_name,
      user_email: populatedUser.user_email,
      user_preferences: {
        cylinder_pref: populatedUser.settings_metadata.cylinder_pref,
        default_ecp_id: populatedUser.settings_metadata.default_ecp_id,
      },
      picture_url: populatedUser.picture_url,
      user_type: userType,
      addable_labs: populatedUser.addable_labs,
    };
  }, []);

  const selectPopulateUser = useCallback(
    (populatedUser: IPopulatedUser): IUserMetadata => {
      const userMetadataState: UserMetadataState = {
        ...populatedUser,
        isLoading: false,
        auth0_id: user?.sub ?? '',
        created_at: user?.[CREATED_AT_KEY],
        user_email: user?.email ?? '',
        settings_metadata: {
          cylinder_pref: user?.[USER_METADATA_KEY]?.cyl_prefix ?? '',
          default_ecp_id: user?.[USER_METADATA_KEY]?.default_ecp_id ?? '',
        },
        picture_url: user?.picture ?? '',
      };
      return adaptPopulatedUserToUserMetadata(userMetadataState);
    },
    [user]
  );

  const { data, ...rest } = useQuery({
    queryKey: USE_POPULATE_USER_CACHE_KEY,
    queryFn: async () => {
      const auth0Id = user?.sub!;
      let endpoint = '/internal/v1/users/populate?';
      const queryParams = new URLSearchParams({
        auth0_id: auth0Id,
      });
      endpoint += queryParams.toString();

      const populatedUserRes = await makeFetchHappen<IPopulatedUser>(endpoint, undefined, {
        additionalHeaders: {
          'User-Id': auth0Id,
        },
      });

      const populatedUser = await populatedUserRes.body;

      return populatedUser;
    },
    enabled: Boolean(user?.sub) && !isLoading,
    // As this data doesn't change frequently, cache indefinitely
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: 6 * 60 * 60 * 1000, // refetch every 6 hours
  });

  return useMemo(() => {
    let mergedMetadata = undefined;
    if (user && data) {
      mergedMetadata = selectPopulateUser(data);
    }
    return {
      data: mergedMetadata,
      ...rest,
    };
  }, [user, data, rest]);
}
