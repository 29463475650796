import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import ArrowLeftIcon from '@assets/icons/ArrowLeft-16-CurrentColor.svg';
import CheckPurpleIcon from '@assets/icons/Check-16-Purple.svg';
import { SearchBar } from '@shared/design_system/SearchBar';
import Address from '@shared/react_components/Address';
import Keyboard from '@shared/react_components/Keyboard';
import { capitalize } from 'lodash';
import { Resource, Screen } from './types';
import useNavigateToResource from './useNavigateToResource';

interface Props {
  currentResource: Resource;
  resourceList: Resource[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  setScreen: Dispatch<SetStateAction<Screen>>;
}

export default function ResourceListScreen({ currentResource, resourceList, setOpen, setScreen }: Props) {
  const navigateToResource = useNavigateToResource();

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(0);

  const filteredResourceList = useMemo(() => {
    return resourceList.filter((resource) => {
      return Object.values(resource).join('').toLowerCase().includes(query.toLowerCase());
    });
  }, [query, resourceList]);

  function handleClick(resource: Resource) {
    if (resource.id === currentResource.id) {
      return;
    }

    setOpen(false);
    navigateToResource(resource);
  }

  return (
    <>
      <div className="tw-w-full">
        <button
          className="tw-appearance-none tw-shadow-none tw-border-none tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2"
          onClick={() => {
            setScreen('initial');
          }}
        >
          <div className="tw-w-5 tw-h-5 tw-rounded-full tw-bg-primary-100 tw-text-primary-500 tw-flex tw-items-center tw-justify-center">
            <ArrowLeftIcon />
          </div>
          <span className="tw-text-sm tw-text-neutral-600">{capitalize(currentResource.type)}s</span>
        </button>
      </div>

      <div className="tw-w-full">
        <SearchBar value={query} onChange={(ev) => setQuery(ev.target.value)} className="tw-my-2" autoFocus autoComplete="off" />
      </div>

      <div className="tw-flex tw-items-center tw-justify-start tw-flex-col tw-max-h-60 tw-overflow-y-auto tw-overflow-x-hidden">
        {filteredResourceList.map((resource) => (
          <button
            key={resource.id}
            className="tw-appearance-none tw-shadow-none tw-border-none tw-cursor-pointer tw-w-full tw-p-2 tw-rounded-md tw-flex tw-items-center tw-justify-between tw-flex-row tw-transition hover:tw-bg-primary-50 focus-visible:tw-outline-none focus-visible:tw-bg-primary-50 tw-text-popover-foreground"
            onClick={() => handleClick(resource)}
            tabIndex={0}
          >
            <div className="tw-flex tw-items-start tw-justify-center tw-flex-col tw-gap-1 tw-text-left">
              <span className="tw-text-sm tw-leading-none tw-font-semibold">{resource.name}</span>
              <span className="tw-text-xs tw-font-normal tw-leading-[1.2] tw-max-w-[180px]">
                {resource.address ? <Address address={resource.address} /> : resource.labInformation}
              </span>
            </div>

            <div className="tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center">
              {resource.id === currentResource.id && <CheckPurpleIcon />}
            </div>
          </button>
        ))}

        {filteredResourceList.length === 0 && (
          <div className="tw-w-full tw-py-8 tw-text-center">
            <p className="tw-text-sm tw-text-neutral-600 tw-m-0">No {currentResource.type}s found.</p>
          </div>
        )}
      </div>

      <Keyboard
        keys={['Enter', 'ArrowUp', 'ArrowDown']}
        onKeyPress={(key) => {
          if (key === 'Enter') {
            if (filteredResourceList.length === 0) {
              return;
            }

            navigateToResource(filteredResourceList[selected]);
          }

          if (key === 'ArrowUp') {
            setSelected((prev) => (prev - 1 + filteredResourceList.length) % filteredResourceList.length);
          } else if (key === 'ArrowDown') {
            setSelected((prev) => (prev + 1) % filteredResourceList.length);
          }
        }}
        handleFocusableElements
      />
    </>
  );
}
