import React from 'react';
import loadable from '@loadable/component';
import { mergeClasses } from './css_utils';

export type IconName =
  | 'CalendarAdd'
  | 'CalendarRemove'
  | 'CopyPlus'
  | 'Check'
  | 'CheckCircle'
  | 'ChevronDown'
  | 'ChevronUp'
  | 'ChevronRight'
  | 'CreditCardPayment'
  | 'CreditCardAdd'
  | 'MagnifyingGlass'
  | 'ClockTimerFast'
  | 'CreateOrder'
  | 'BadgeCheck'
  | 'BankCertificate'
  | 'BankClock'
  | 'Bell'
  | 'Dot'
  | 'DownloadFile'
  | 'DuplicateOrder'
  | 'ExclamationCircle'
  | 'Exclamation'
  | 'Expand'
  | 'ExternalLink'
  | 'Eye'
  | 'EyeHide'
  | 'FileFolded'
  | 'Flag'
  | 'FlagOutline'
  | 'FolderFiles'
  | 'Funnel'
  | 'FunnelWithX'
  | 'InfoCircle'
  | 'LightningBolt'
  | 'Lock'
  | 'Magic'
  | 'MenuBurger'
  | 'Moon'
  | 'Path0To+'
  | 'Plus'
  | 'Print'
  | 'QuestionCircle'
  | 'Receipt'
  | 'RedoOrder'
  | 'Refresh'
  | 'Send'
  | 'ThreeDotsVertical'
  | 'ThreeDotsHorizontal'
  | 'TrashCan'
  | 'Truck'
  | 'Search'
  | 'SettingSlidersHorizontal'
  | 'Star'
  | 'StarCircleOutline'
  | 'StarOutline'
  | 'Sun'
  | 'X';

type IconSize = 16 | 24 | 40;

const AsyncIcon = loadable((props: { filename: string }) => import(`@assets/icons/${props.filename}`), {
  cacheKey: (props) => props.filename,
});

export interface IconProps {
  /**
   * The name of the icon to load. Must match the filename in the assets/icons folder.
   */
  name: IconName;
  /**
   * The size of the icon to load. Usually 16, 24, or 40. Must match the filename in the assets/icons folder.
   */
  size?: IconSize;
  /**
   * Additional classes to apply to the icon container. Most icons use a "currentColor" fill,
   * so you can use text classes to change the color. Ex: "tw-text-primary-500".
   */
  className?: string;
  /**
   * Optional variant to apply to the icon. Most don't have variants, but some do to change the fill color.
   * CurrentColor: Solid fill color that matches the text color.
   * White: Inner path is white, fill color matches the text color.
   */
  variant?: 'CurrentColor' | 'White';
  /**
   * Optional data-testid attribute to apply to the icon container.
   */
  'data-testid'?: string;
  /**
   * Optional onClick handler to apply to the icon.
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 *
 * A component to load custom icons from the assets/icons folder.
 *
 */
export default function Icon({ name, size = 16, className, variant = 'CurrentColor', 'data-testid': dataTestId, onClick }: IconProps) {
  const filename = `${name}-${size}-${variant}.svg`;

  return (
    <div data-testid={dataTestId} className={mergeClasses('icon tw-flex tw-items-center', className)} onClick={onClick}>
      <AsyncIcon filename={filename} />
    </div>
  );
}
