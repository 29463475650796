import {
  useFeatureFlagEnabled as usePostHogFeatureFlagEnabled,
  useFeatureFlagVariantKey as usePostHogFeatureFlagVariantKey,
} from 'posthog-js/react';

type Flags =
  | 'ocr-enabled'
  | 'pos-enabled'
  | 'pos-terminal-enabled'
  | 'mtp-disabled'
  | 'flagged-orders-enabled'
  | 'patient-id-validation-enabled'
  | 'order-status-history-enabled'
  | 'dark-mode-toggle-enabled'
  | 'essentials-enabled';

export function useFeatureFlagEnabled(flag: Flags): boolean | undefined {
  return usePostHogFeatureFlagEnabled(flag);
}

type ExperimentKeyValue = {
  'frames-data-modal'?: 'v2' | 'control';
};

export function useFeatureFlagVariantKey(experiment: keyof ExperimentKeyValue): ExperimentKeyValue[typeof experiment] | undefined {
  return usePostHogFeatureFlagVariantKey(experiment) as ExperimentKeyValue[typeof experiment] | undefined;
}
