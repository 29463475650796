import SpecCheckSymbolLogo from '@assets/icons/logo-symbol.svg';
import useOfficeLabStore from '@shared/state/office-lab-store';
import { capitalize } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  showText?: boolean;
}

const textClassName = 'tw-text-xl tw-font-bold tw-text-white tw-no-underline tw-select-none';

export default function Logo({ showText }: Props) {
  const { office } = useOfficeLabStore();
  const isPremiumUser = office.sub_tier !== 'free';
  const subTierLabel = capitalize(office.sub_tier);
  return (
    <NavLink
      id="sc-logo"
      to="./"
      className="tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-row md:tw-px-1 tw-gap-3 tw-no-underline"
    >
      <SpecCheckSymbolLogo className="tw-w-10 md:tw-w-12" />
      {showText && (
        <span className={textClassName}>
          <span className="tw-hidden sm:tw-inline">SpecCheck</span> {isPremiumUser && <span>{subTierLabel}</span>}
        </span>
      )}
    </NavLink>
  );
}
