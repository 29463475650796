import { ILab } from '@core/models/lab.model';
import { IOffice } from '@core/models/office.model';
import { create } from 'zustand';

type OfficeLabState = {
  office: IOffice;
  lab: ILab;
};

type StoreShape = OfficeLabState & {
  setActiveOfficeLab: (office: IOffice, lab: ILab) => void;
};

const initialState: OfficeLabState = {
  office: {
    id: '',
    name: '',
    address: {
      line1: '',
      line2: null,
      city: '',
      state: '',
      country: '',
      postal_code: '',
    },
    pms: '',
    lab_metadata: [],
    permissions: [],
    sub_tier: 'free',
    flagged_order_threshold_days: 14,
    trial_days_remaining: 0,
  },
  lab: {
    id: '',
    name: '',
    account_number: '',
    lab_pay: false,
    intercom_settings: null,
    is_default: false,
    permissions: [],
  },
};

const useOfficeLabStore = create<StoreShape>((set) => ({
  ...initialState,
  setActiveOfficeLab: (office, lab) =>
    set(() => ({
      office,
      lab,
    })),
}));

export default useOfficeLabStore;
